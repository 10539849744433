





















































import { Vue, Component } from "vue-property-decorator";
import Quill from "quill";
import { VueEditor } from "vue2-editor";
import htmlEditButton from "quill-html-edit-button";
import axios from "axios";

Quill.register("modules/htmlEditButton", htmlEditButton);

@Component({
  metaInfo() {
    return {
      title: "Gözat / Görev / Admin"
    };
  },
  components: {
    VueEditor
  }
})
export default class AdminTaskDetailsView extends Vue {
  taskAssignment = {} as {
    id: string;
    description: string;
    createDate: Date;
    startDate: Date;
    finishDate: Date;
    completionDate: Date | null;
    status: "Uncompleted" | "Completed" | "Rejected" | "Cancelled";
    user: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
    },
    activity: {
        id: string;
        urlCode: string;
        title: string;
        description: string;
        points: number;
        group: {
            id: string;
            title: string;
            description: string;
        }
    }
};

  async getTaskDetails() {
    try {
      const id = this.$route.params.id;
      const authHeader = this.$store.getters["auth/requestAuthHeader"];

      // Get task assigment
      var res = await axios.get(`/api/task/get-details/${id}`, {
        headers: authHeader
      });

      this.taskAssignment = res.data;
      this.taskAssignment.createDate = new Date(res.data.createDate);
      this.taskAssignment.startDate = new Date(res.data.startDate);
      this.taskAssignment.finishDate = new Date(res.data.finishDate);

      console.log(this.taskAssignment);
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Görev detayları alınamadı.`
      });

      throw e;
    }
  }

  async completeTask() {
    const confirmation = await this.$confirm("Bu işlem geri alınamaz. Görevi tamamlandı olarak işaretlemek istediğinizden emin misiniz?", { title: "Emin misiniz?" });

    if (!confirmation) return;

    try {
      // Görevi tamamlandı olarak işaretle
      var res = await axios.get(`/api/task/complete/${this.taskAssignment.id}`, {
        headers: this.$store.getters["auth/requestAuthHeader"]
      });

      this.$notify({ type: "success", text: "Görev tamamlandı olarak işaretlendi. Lütfen bekleyiniz..." });

      const timer1 = setTimeout(() => {
        this.$router.push({ name: "AdminTasks" });

        clearTimeout(timer1);
      }, 3000);
    } catch (e) {
      this.$notify({
        type: "eror",
        title: "Hata!",
        text: "Görev tamamlandı olarak işaretlenemedi."
      });

      throw e;
    }
  }

  async rejectTask() {
    const confirmation = await this.$confirm("Bu işlem geri alınamaz. Görevi reddetmek istediğinizden emin misiniz?", { title: "Emin misiniz?" });

    if (!confirmation) return;

    try {
      // Görevi reddet
      var res = await axios.get(`/api/task/reject/${this.taskAssignment.id}`, {
        headers: this.$store.getters["auth/requestAuthHeader"]
      });

      this.$notify({ type: "success", text: "Görev reddedildi. Lütfen bekleyiniz..." });

      const timer1 = setTimeout(() => {
        this.$router.push({ name: "AdminTasks" });

        clearTimeout(timer1);
      }, 3000);
    } catch (e) {
      this.$notify({
        type: "eror",
        title: "Hata!",
        text: "Görev reddedilemedi."
      });

      throw e;
    }
  }

  async mounted() {
    await this.getTaskDetails();
  }
}
